/* eslint-disable max-len */
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button, Form, message, Modal, Tooltip,
} from 'antd'
import cn, { Argument } from 'classnames'
import { observer } from 'mobx-react'
import { match } from 'ts-pattern'

import subscriptionStore from '@store/subscription'
import userStore from '@store/user'
import authorizedRoutes from '@router/authorized-routes'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import paddle from '@shared/services/paddle'
import { showErrorMessage } from '@utils/messages'
import pluralEnd from '@utils/plural-end'
import AcceptAcademicPlans from './AcceptAcademicPlans'

interface PlanCardButtonsProps {
  plan: PaddleSubscriptionPlan | AcademicSubscriptionPlan
  className?: Argument
}

interface LimitWarningProps {
  name: string
  currentValue: string | number
  maxValue: string | number
  highlightCondition: boolean
}

interface SubscriptionFormProps {
  onFinish?: () => void
  className?: Argument
  text: string
  disabled?: boolean
  loading?: boolean
}

interface CrossSubscriptionLimitsErrorData {
  maxAllowedStoredRecords: number
  planName: SDFPlanName | AcademicSubscriptionPlan
  userStoredRecords: number
  userFileCount: number
  maxAllowedFileCount: string
}

const CROSS_SUBSCRIPTION_ERROR = 'Cannot change Subscription. Try again later.'

const SubscriptionForm = ({
  onFinish, className, text, disabled, loading,
}: SubscriptionFormProps) => (
  <Form
    className={cn(className)}
    onFinish={onFinish}
  >
    <AcceptAcademicPlans type="subscribe" />
    <Form.Item className="!mb-0">
      <Button
        type="primary"
        className="w-full"
        htmlType="submit"
        disabled={disabled}
        loading={loading}
      >
        {text}
      </Button>
    </Form.Item>
  </Form>
)

const LimitWarning = ({
  name, currentValue, maxValue, highlightCondition,
}: LimitWarningProps) => (
  <div>
    <span className="text-gray-40">{name}: </span>
    <span className={cn({ 'text-danger': highlightCondition })}>
      {currentValue}
    </span>
    <span> / {maxValue}</span>
  </div>
)

const PlanCardButtons = ({ plan }: PlanCardButtonsProps): JSX.Element => {
  const {
    status, dueDate, planId: currentPlanId,
  } = subscriptionStore
  const history = useHistory()

  const onFinish = useCallback(async () => {
    try {
      await userStore.updateTermsOfServiceConsent(true)
      history.replace(authorizedRoutes.Files.path)
    } catch (error) {
      showErrorMessage()
    }
  }, [history])
  const [prorateLoading, setProrateLoading] = useState(false)
  const isCurrentPlan = plan.id === currentPlanId
  const handleCrossSubscriptionLimitsError = useCallback((data: CrossSubscriptionLimitsErrorData) => {
    const {
      planName,
      maxAllowedStoredRecords,
      maxAllowedFileCount,
      userStoredRecords,
      userFileCount,
    } = data
    const currentRowsCount = userStoredRecords.toLocaleString('ru')
    const maxRowsCount = maxAllowedStoredRecords.toLocaleString('ru')
    const maxFilesCount = maxAllowedFileCount === 'unlimited' ? Infinity : Number.parseInt(maxAllowedFileCount, 10)
    const isRowsCountLimitExceeded = userStoredRecords > maxAllowedStoredRecords
    const isFilesCountLimitExceeded = userFileCount > maxFilesCount
    const showFilesCountWarning = planName === 'Editor Edition' && maxFilesCount < Infinity
    const content = (
      <div className="mt-6">
        <div>
          Currently it is not possible to change your subscription.
          The amount of data that you currently have in the systems exceeds the {planName} limits.
          To change the subscription, you should delete some files or stay on the current subscription.
        </div>
        <div className="mt-6">
          <span>
            Available storage for {planName} subscription:
          </span>
          <div className="mt-2">
            <LimitWarning
              name="Rows"
              currentValue={currentRowsCount}
              maxValue={`${maxRowsCount} rows`}
              highlightCondition={isRowsCountLimitExceeded}
            />
            {showFilesCountWarning && (
              <LimitWarning
                name="Files amount"
                currentValue={userFileCount}
                maxValue={`${maxFilesCount} file${pluralEnd(maxFilesCount)}`}
                highlightCondition={isFilesCountLimitExceeded}
              />
            )}
          </div>
        </div>
      </div>
    )
    Modal.confirm({
      title: 'Data exceeds new subscription limit',
      width: 390,
      icon: <ExclamationCircleFilled />,
      content,
      okText: 'Go to file list',
      okType: 'primary',
      okButtonProps: { size: 'large' },
      cancelText: 'Cancel',
      cancelButtonProps: { ghost: true, size: 'large' },
      onOk: () => history.push(authorizedRoutes.Files.path),
    })
  }, [history])
  const createSubscription = useCallback(async () => {
    try {
      await paddle.createSubscription({
        email: userStore.email,
        product: plan.id,
      })
      onFinish()
    } catch (error) {
      if (isApiCustomException<CrossSubscriptionLimitsErrorData>(error, 'CrossSubscriptionLimitsViolationException')) {
        handleCrossSubscriptionLimitsError(error.data)
      } else if (isApiCustomException(error, 'SubscriptionAlreadyExistsException')) {
        message.error('Subscription already exists. Try to refresh the page.')
      } else if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [plan.id, onFinish, handleCrossSubscriptionLimitsError])
  const changeSubscription = useCallback(async () => {
    try {
      await paddle.changeSubscription({
        email: userStore.email,
        product: plan.id,
      })
      await subscriptionStore.fetchSubscriptionDetailsWithPlanId(plan.id)
      message.success('Subscription was successfully changed.')
    } catch (error) {
      message.error(CROSS_SUBSCRIPTION_ERROR)
    }
  }, [plan.id])
  const initSubscriptionChange = useCallback(async () => {
    setProrateLoading(true)

    try {
      const amount = await paddle.previewProrateCosts({
        email: userStore.email,
        product: plan.id,
      })
      const charge = amount > 0 ? amount : 0
      const content = (
        <div className="mt-5">
          <div>
            Are you sure you want to change your subscription?
            We will prorate costs accordingly and you will be charged now.
          </div>
          <div className="mt-4">
            You are changing your subscription to the {plan.name}.
          </div>
          <div className="mt-2 font-bold">
            <div>You will be charged ${charge} now.</div>
          </div>
        </div>
      )
      Modal.confirm({
        width: 368,
        title: 'Change subscription',
        icon: <ExclamationCircleFilled className="!leading-0 !text-xl" />,
        content,
        okText: 'Change subscription',
        okType: 'primary',
        cancelText: 'Cancel',
        okButtonProps: { size: 'large' },
        cancelButtonProps: { ghost: true, size: 'large' },
        onOk: changeSubscription,
      })
    } catch (error) {
      if (isApiCustomException<CrossSubscriptionLimitsErrorData>(error, 'CrossSubscriptionLimitsViolationException')) {
        handleCrossSubscriptionLimitsError(error.data)
      } else {
        message.error(CROSS_SUBSCRIPTION_ERROR)
      }
    } finally {
      setProrateLoading(false)
    }
  }, [plan.id, plan.name, changeSubscription, handleCrossSubscriptionLimitsError])
  const changePaymentMethod = useCallback(async () => {
    try {
      await paddle.changePaymentMethod()
    } catch (error) {
      if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [])
  const cancelSubscription = useCallback(async () => {
    try {
      await paddle.cancelSubscription()
    } catch (error) {
      if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [])
  const confirmUnsubscribe = useCallback(() => {
    const content = (
      <div>
        <div>
          Are you sure you want to unsubscribe?
          Your subscription will be active until the end of the period.
        </div>
        <div className="mt-3 border-l-4 border-danger p-2 pl-3 bg-[#fad7d7] dark:bg-[#59454d] rounded">
          After the end of the paid period,
          all your files will be permanently deleted
          and the access to Adroit REPOSITORY functionality will be unavailable
        </div>
      </div>
    )
    Modal.confirm({
      title: 'Unsubscribe',
      width: 368,
      icon: <ExclamationCircleFilled />,
      content,
      okText: 'Unsubscribe',
      okType: 'primary',
      okButtonProps: { size: 'large' },
      cancelText: 'Cancel',
      cancelButtonProps: { ghost: true, size: 'large' },
      onOk: cancelSubscription,
    })
  }, [cancelSubscription])

  const confirmResubscribe = useCallback(() => {
    Modal.confirm({
      width: 368,
      title: 'New subscription',
      icon: <ExclamationCircleFilled />,
      content: `
        You still have access to the functionality until ${dueDate}.
        Are you sure you want to create a new subscription?
        You will not be eligible for a refund of your remaining subscription period.
      `,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'Cancel',
      okButtonProps: { size: 'large' },
      cancelButtonProps: { ghost: true, size: 'large' },
      onOk: createSubscription,
    })
  }, [createSubscription, dueDate])

  return match(status)
    .with('none', 'expired', () => (
      <SubscriptionForm
        className="mt-8"
        onFinish={createSubscription}
        text="Subscribe"
      />
    ))
    .with('cancelled', () => (
      <div className="mt-8">
        {isCurrentPlan && <div className="text-xs">Active until {dueDate}</div>}
        <SubscriptionForm
          className="mt-3"
          onFinish={confirmResubscribe}
          text="Subscribe"
        />
      </div>
    ))
    .with('trialing', 'active', currentStatus => (isCurrentPlan ? (
      <div className="mt-8">
        <span className="dark:text-white text-sm">Academic Use Only. Fair use rules apply. For Profit must use standard license</span>
        <div className="border-b border-solid dark:border-gray-60 border-white border-opacity-20 w-full mt-4">           </div>
        <div className="text-xs mt-4">Your next billing date is {dueDate}</div>
        <Button
          type="primary"
          className="w-full mt-3"
          onClick={changePaymentMethod}
        >
          Change payment method
        </Button>
        <Button
          ghost
          className="w-full mt-3 !text-[#393E48] !bg-white "
          onClick={confirmUnsubscribe}
        >
          Cancel subscription
        </Button>
      </div>
    ) : (
      <SubscriptionForm
        className="mt-8"
        loading={prorateLoading}
        disabled={currentStatus === 'trialing'}
        onFinish={initSubscriptionChange}
        text="Change subscription"
      />
    )))
    .with('paused', () => (isCurrentPlan ? (
      <div className="mt-8">
        <div className="text-xs">Paused due to payment failure</div>
        <Tooltip
          title="Cancellation of a subscription is available only through technical support"
          overlayInnerStyle={{ textAlign: 'center' }}
          placement="top"
        >
          <Button
            ghost
            block
            disabled
            className="w-full mt-3"
          >
            Cancel subscription
          </Button>
        </Tooltip>
        <Button
          type="primary"
          className="w-full mt-3"
          onClick={changePaymentMethod}
        >
          Change payment method
        </Button>
      </div>
    ) : (
      <SubscriptionForm
        className="mt-8"
        disabled
        text="Change subscription"
      />
    )))
    .exhaustive()
}
export default observer(PlanCardButtons)
